import { MantineForm } from '../AddEditMatterForm';
import {
  Button,
  Group,
  Radio,
  Stack,
  Text,
  TextInput,
  TextInputProps,
  Title,
} from '@mantine/core';
import { ActionIcon } from '@mantine/core';
import { AtSignIcon, PhoneIcon, PlusIcon, Trash2Icon } from 'lucide-react';
import styles from './MatterContactForm.module.css';
import { v4 as uuidv4 } from 'uuid';
import { EmailAddress, PhoneNumber } from '../../../database/baseTypes';

const MatterContactForm = ({
  title,
  contactIndex,
  isPrimary,
  setPrimary,
  form,
  canDelete,
  onDelete,
}: {
  title: string;
  contactIndex: number;
  isPrimary: boolean;
  setPrimary: (isPrimary: boolean) => void;
  form: MantineForm;
  canDelete: boolean;
  onDelete: () => void;
}) => {
  return (
    <Group align='stretch' pt='xs' pb='xs'>
      <Stack justify='flex-start'>
        <Radio
          aria-label='Primary contact'
          checked={isPrimary}
          onChange={() => setPrimary(!isPrimary)}
          color='pink.4'
        />
      </Stack>

      <Stack align='stretch' flex={1}>
        <MatterContactFormHeader
          title={title}
          isPrimary={isPrimary}
          canDelete={canDelete}
          onDelete={onDelete}
        />
        <Stack gap='xl'>
          <Group>
            <TextInput
              label='First Name'
              key={form.key(`humanContacts.${contactIndex}.firstName`)}
              {...form.getInputProps(`humanContacts.${contactIndex}.firstName`)}
              flex={1}
            />
            <TextInput
              label='Last Name'
              key={form.key(`humanContacts.${contactIndex}.lastName`)}
              {...form.getInputProps(`humanContacts.${contactIndex}.lastName`)}
              flex={1}
            />
          </Group>
          <PhoneNumbersForm form={form} contactIndex={contactIndex} />
          <EmailAddressesForm form={form} contactIndex={contactIndex} />
        </Stack>
      </Stack>
    </Group>
  );
};

export default MatterContactForm;

const PhoneNumbersForm = ({
  form,
  contactIndex,
}: {
  form: MantineForm;
  contactIndex: number;
}) => {
  const phoneNumbers =
    form.getValues().humanContacts[contactIndex].phoneNumbers;

  const primaryPhoneNumberId =
    form.getValues().humanContacts[contactIndex].primaryPhoneNumberId;

  const handleDelete = (phone: PhoneNumber, phoneIndex: number) => {
    if (phone.id === primaryPhoneNumberId) {
      const nextPhoneNumber = phoneNumbers?.find(
        (p) => p.id !== primaryPhoneNumberId
      );
      form.setFieldValue(
        `humanContacts.${contactIndex}.primaryPhoneNumberId`,
        nextPhoneNumber?.id ?? null
      );
    }
    form.removeListItem(
      `humanContacts.${contactIndex}.phoneNumbers`,
      phoneIndex
    );
  };

  return (
    <div className='phone-numbers-form'>
      <CollectionHeader
        collectionName='Phone Numbers'
        collectionLength={phoneNumbers?.length ?? 0}
      />
      <ul style={{ margin: '0' }}>
        {phoneNumbers?.map((phone, phoneIndex) => (
          <CollectionRow
            key={phone.id}
            item={phone}
            label={`Phone number ${phoneIndex + 1}`}
            textInputProps={form.getInputProps(
              `humanContacts.${contactIndex}.phoneNumbers.${phoneIndex}.value`
            )}
            isPrimary={
              form.getValues().humanContacts[contactIndex]
                .primaryPhoneNumberId === phone.id
            }
            canDelete={(phoneNumbers?.length ?? 0) > 1}
            textInputLeftSection={<PhoneIcon size={16} />}
            onPrimaryChange={() => {
              form.setFieldValue(
                `humanContacts.${contactIndex}.primaryPhoneNumberId`,
                phone.id
              );
            }}
            onDeleteClick={() => handleDelete(phone, phoneIndex)}
          />
        ))}
      </ul>
      <AddToCollectionButton
        title='Add a phone number'
        onClick={() => {
          form.insertListItem(`humanContacts.${contactIndex}.phoneNumbers`, {
            id: uuidv4(),
            value: '',
          });
        }}
      />
    </div>
  );
};

const EmailAddressesForm = ({
  form,
  contactIndex,
}: {
  form: MantineForm;
  contactIndex: number;
}) => {
  const emailAddresses =
    form.getValues().humanContacts[contactIndex].emailAddresses;

  const primaryEmailAddressId =
    form.getValues().humanContacts[contactIndex].primaryEmailAddressId;

  const handleDelete = (email: EmailAddress, emailIndex: number) => {
    if (email.id === primaryEmailAddressId) {
      const nextEmailAddress = emailAddresses?.find(
        (e) => e.id !== primaryEmailAddressId
      );
      form.setFieldValue(
        `humanContacts.${contactIndex}.primaryEmailAddressId`,
        nextEmailAddress?.id ?? null
      );
    }
  };

  return (
    <div className='email-addresses-form'>
      <CollectionHeader
        collectionName='Email Addresses'
        collectionLength={emailAddresses?.length ?? 0}
      />
      <ul style={{ margin: '0' }}>
        {emailAddresses?.map((email, emailIndex) => (
          <CollectionRow
            key={email.id}
            item={email}
            label={`Email address ${emailIndex + 1}`}
            textInputProps={form.getInputProps(
              `humanContacts.${contactIndex}.emailAddresses.${emailIndex}.value`
            )}
            isPrimary={
              form.getValues().humanContacts[contactIndex]
                .primaryEmailAddressId === email.id
            }
            canDelete={(emailAddresses?.length ?? 0) > 1}
            textInputLeftSection={<AtSignIcon size={16} />}
            onPrimaryChange={() => {
              form.setFieldValue(
                `humanContacts.${contactIndex}.primaryEmailAddressId`,
                email.id
              );
            }}
            onDeleteClick={() => handleDelete(email, emailIndex)}
          />
        ))}
      </ul>
      <AddToCollectionButton
        title='Add an email address'
        onClick={() => {
          form.insertListItem(`humanContacts.${contactIndex}.emailAddresses`, {
            id: uuidv4(),
            value: '',
          });
        }}
      />
    </div>
  );
};

const MatterContactFormHeader = ({
  title,
  isPrimary,
  canDelete,
  onDelete,
}: {
  title: string;
  isPrimary: boolean;
  canDelete: boolean;
  onDelete: () => void;
}) => {
  return (
    <Group justify='space-between'>
      <Group align='baseline' gap='sm' h='2rem'>
        <Title order={3} style={{ margin: '0', color: '#333' }}>
          {title}
        </Title>
        {isPrimary && (
          <Text
            fw={400}
            c='pink.4'
            style={{ fontSize: '0.75rem', letterSpacing: '0.02rem' }}
          >
            Primary
          </Text>
        )}
      </Group>
      {canDelete && (
        <ActionIcon
          variant='subtle'
          aria-label={`Delete ${title}`}
          onClick={onDelete}
        >
          <Trash2Icon style={{ width: '70%', height: '70%' }} />
        </ActionIcon>
      )}
    </Group>
  );
};

const CollectionRow = ({
  item,
  label,
  textInputProps,
  isPrimary,
  canDelete,
  textInputLeftSection,
  onPrimaryChange,
  onDeleteClick,
}: {
  item: PhoneNumber | EmailAddress;
  label: string;
  textInputProps: TextInputProps;
  isPrimary: boolean;
  canDelete: boolean;
  textInputLeftSection: React.ReactNode;
  onPrimaryChange: () => void;
  onDeleteClick: () => void;
}) => {
  return (
    <li className={styles.collectionGridLine} key={item.id}>
      <TextInput
        aria-label={label}
        leftSection={textInputLeftSection}
        key={`${item.id}-input`}
        {...textInputProps}
        flex={1}
      />

      <Radio
        classNames={styles}
        style={{ justifySelf: 'center' }}
        checked={isPrimary}
        onChange={onPrimaryChange}
        color='blue.4'
      />

      {canDelete && (
        <ActionIcon variant='subtle' onClick={onDeleteClick}>
          <Trash2Icon size={16} />
        </ActionIcon>
      )}
    </li>
  );
};

const CollectionHeader = ({
  collectionName,
  collectionLength,
}: {
  collectionName: string;
  collectionLength: number;
}) => {
  return (
    <div className={styles.collectionGridLine}>
      <Text size='sm' fw={500}>
        {collectionName}
      </Text>
      <Text size='xs' style={{ justifySelf: 'center', alignSelf: 'flex-end' }}>
        {collectionLength > 0 && 'Primary'}
      </Text>
      <Text size='xs'></Text>
    </div>
  );
};

const AddToCollectionButton = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => {
  return (
    <Button
      variant='subtle'
      leftSection={<PlusIcon size={16} />}
      className={styles.addToCollectionButton}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};
