import { useEffect } from 'react';

/**
 * Exposes a file needed by Google to verify ownership of the domain.
 *
 * @link https://support.google.com/webmasters/answer/9008080?hl=en#html_verification
 * @returns
 */
const GoogleVerification = () => {
  useEffect(() => {
    fetch('/google9db2b2215cb7590d.html')
      .then((response) => response.text())
      .then((content) => {
        document.body.innerHTML = content;
      })
      .catch((error) => console.error('Error loading verification file:', error));
  }, []);

  return null;
};

export default GoogleVerification;
