import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://9abe55543255dfe358a49c02c7319552@o4507376227713024.ingest.us.sentry.io/4507647570935808',
  enabled: process.env.NODE_ENV === 'development' ? false : true,
  environment: process.env.NODE_ENV == 'development' ? 'development' : 'production',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**
 * Captures an exception and sends it to Sentry.
 */
export const captureException = Sentry.captureException;

/**
 * Adds a breadcrumb that will be attached to any future exceptions.
 *
 * When an exception is reported to Sentry, a list of breadcrumbs leading up to the exception will
 * be shown on the exception's "issue" page.
 *
 * This helps us understand the steps needed to reproduce the error.
 *
 * For more info and example screenshots, see:
 * https://docs.sentry.io/product/issues/issue-details/breadcrumbs/
 */
export const addExceptionBreadcrumb = Sentry.addBreadcrumb;
