import { useEffect, useState } from 'react';
import { useUsers } from '../database/hooks';
import { SelectOption } from './types';

export const useUserSelectOptions = (opts?: { includeOptionAll: boolean }) => {
  const users = useUsers();
  const [userSelectOptions, setUserSelectOptions] = useState<
    SelectOption<string>[]
  >([]);
  useEffect(() => {
    if (!users) {
      setUserSelectOptions([]);
      return;
    }
    const options = users
      .map((user) => {
        return {
          value: user.id,
          label: user.firstName + ' ' + user.lastName,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    if (opts?.includeOptionAll === true) {
      options.unshift({ value: 'all', label: 'All' });
    }
    setUserSelectOptions(options);
  }, [users, opts?.includeOptionAll]);
  return userSelectOptions;
};
