import { Suspense, lazy, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Callback from './Callback';
import { AuthenticatedSessionContext } from './authenticatedSession/AuthenticatedSessionContext';
import { LoadingView } from './components/LoadingView';
const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'));
const Flywheel = lazy(() => import('./views/Matters/Flywheel/Flywheel'));
const Matters = lazy(() => import('./views/Matters/Matters'));
const Fees = lazy(() => import('./views/Fees'));
const Expenses = lazy(() => import('./views/Expenses'));
const Tasks = lazy(() => import('./views/Tasks/Tasks'));
const BizDev = lazy(() => import('./views/BizDev'));
const Invoices = lazy(() => import('./views/Invoices/Invoices'));
const OpsHR = lazy(() => import('./views/Financials/OpsHR'));
const Resources = lazy(() => import('./views/KnowledgeBase/KnowledgeBase'));
const Wellness = lazy(() => import('./views/Wellness'));
const Settings = lazy(() => import('./views/Settings/Settings'));
const MatterDashboard = lazy(
  () => import('./views/MatterDashboard/MatterDashboard')
);
const Auth = lazy(() => import('./views/Auth/Auth'));
const ClaimsSpreadsheet = lazy(
  () => import('./views/ClaimsSpreadsheet/ClaimsSpreadsheet')
);
const MattersSpreadsheet = lazy(
  () => import('./views/Matters/MattersSpreadsheet/MattersSpreadsheet')
);
const ClioSync = lazy(() => import('./components/ClioSync/ClioSync'));

const AppRoutes = () => {
  const authSession = useContext(AuthenticatedSessionContext);

  return (
    <Suspense fallback={<LoadingView />}>
      <Switch>
        <Route path='/dashboard/:modal'>
          {authSession ? <Dashboard /> : <Auth />}
        </Route>
        <Route path='/dashboard'>
          {authSession ? <Dashboard /> : <Auth />}
        </Route>
        <Route path='/flywheel'>{authSession ? <Flywheel /> : <Auth />} </Route>
        <Route path='/matters/:tab'>
          {authSession ? <Matters /> : <Auth />}{' '}
        </Route>
        <Route path='/matters'>{authSession ? <Matters /> : <Auth />} </Route>

        <Route path='/fees'>{authSession ? <Fees /> : <Auth />} </Route>
        <Route path='/expenses'>{authSession ? <Expenses /> : <Auth />} </Route>
        <Route path='/tasks'>{authSession ? <Tasks /> : <Auth />} </Route>
        <Route path='/biz-dev'>{authSession ? <BizDev /> : <Auth />} </Route>
        <Route path='/invoices'>{authSession ? <Invoices /> : <Auth />} </Route>
        <Route path='/ops-hr'>{authSession ? <OpsHR /> : <Auth />} </Route>
        <Route path='/resources'>
          {authSession ? <Resources /> : <Auth />}{' '}
        </Route>
        <Route path='/wellness'>{authSession ? <Wellness /> : <Auth />} </Route>
        <Route path='/settings'>{authSession ? <Settings /> : <Auth />} </Route>
        <Route path='/matter/:id'>
          {authSession ? <MatterDashboard /> : <Auth />}
        </Route>
        <Route path='/spreadsheet'>
          {authSession ? <MattersSpreadsheet /> : <Auth />}
        </Route>
        <Route path='/claims'>
          {authSession ? <ClaimsSpreadsheet /> : <Auth />}
        </Route>
        <Route exact path='/callback'>
          <Callback />
        </Route>
        <Route path='/login'>
          {authSession ? <Redirect to='/dashboard' /> : <Auth />}
        </Route>
        <Route path='/clio-sync'>{authSession ? <ClioSync /> : <Auth />}</Route>
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
