import { Loader } from '@mantine/core';
import { CSSProperties } from 'react';

export const LoadingView = ({
  includeText = true,
  includeAnimation = false,
  style,
}: {
  includeText?: boolean;
  includeAnimation?: boolean;
  style?: CSSProperties;
}) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {includeText === true && (
          <h3 style={{ color: '#999', fontWeight: '400' }}>Loading...</h3>
        )}
        {includeAnimation === true && <Loader type='dots' color='pink.4' />}
      </div>
    </div>
  );
};
