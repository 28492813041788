import { useState, useRef, lazy, Suspense, useContext } from 'react';
import './PlusButton.css';
import PlusButtonDropdown from './PlusButtonDropdown';
const NotesModal = lazy(() => import('../NotesModal'));
const TimeEntryModal = lazy(() => import('../TimeEntryModal/TimeEntryModal'));
const ExpenseEntryModal = lazy(
  () => import('../ExpenseEntryModal/ExpenseEntryModal')
);
const TaskModal = lazy(() => import('../TaskModal/TaskModal'));
import useOnClickOutside from './useOnClickOutside';
import { AuthenticatedSessionContext } from '../../authenticatedSession/AuthenticatedSessionContext';
import AddEditMatterModal from '../AddEditMatter/AddEditMatterModal';
import { LoadingView } from '../LoadingView';

const PlusButton = () => {
  const authSession = useContext(AuthenticatedSessionContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showNewMatterPopup, setShowNewMatterPopup] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showTimeEntryModal, setShowTimeEntryModal] = useState(false);
  const [showExpenseEntryModal, setShowExpenseEntryModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const dropdownRef = useRef<any>();

  useOnClickOutside(dropdownRef, () => {
    setDropdownVisible(false);
    setShowNewMatterPopup(false);
    setShowNotesModal(false);
  });

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleNewMatterClick = async () => {
    if (!authSession) {
      return;
    }
    setShowNewMatterPopup(true);
  };

  const handleNoteClick = () => {
    setShowNotesModal(true);
  };

  const handleTimeClick = () => {
    setShowTimeEntryModal(true);
  };

  const handleExpenseClick = () => {
    setShowExpenseEntryModal(true);
  };

  const handleTaskClick = () => {
    setShowTaskModal(true);
  };

  return (
    <div className='plus-button-container' ref={dropdownRef}>
      <button className='plus-button' onClick={toggleDropdown}>
        <span className='plus-icon'>+</span>
      </button>
      <PlusButtonDropdown
        visible={dropdownVisible}
        onNewMatterClick={handleNewMatterClick}
        onNoteClick={handleNoteClick}
        onTimeClick={handleTimeClick}
        onExpenseClick={handleExpenseClick}
        onTaskClick={handleTaskClick}
        toggleDropdown={toggleDropdown}
      />
      {showNewMatterPopup && (
        <Suspense fallback={<LoadingView />}>
          <AddEditMatterModal onClose={() => setShowNewMatterPopup(false)} />
        </Suspense>
      )}
      {showNotesModal && (
        <Suspense fallback={<LoadingView />}>
          <NotesModal
            fixedMatterId={undefined}
            onClose={() => setShowNotesModal(false)}
          />
        </Suspense>
      )}
      {showTimeEntryModal && (
        <Suspense fallback={<LoadingView />}>
          <TimeEntryModal
            fixedMatterId={undefined}
            onClose={() => {
              setShowTimeEntryModal(false);
            }}
          />
        </Suspense>
      )}
      {showExpenseEntryModal && (
        <Suspense fallback={<LoadingView />}>
          <ExpenseEntryModal
            fixedMatterId={undefined}
            onClose={() => setShowExpenseEntryModal(false)}
          />
        </Suspense>
      )}
      {showTaskModal && (
        <Suspense fallback={<LoadingView />}>
          <TaskModal
            fixedMatterId={undefined}
            onClose={() => setShowTaskModal(false)}
          />
        </Suspense>
      )}
    </div>
  );
};

export default PlusButton;
