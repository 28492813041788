import { storage } from '../firebaseConfig.js';

export async function loadImageSrc(
  firmId: string,
  fileName: string
): Promise<string> {
  const fileRef = storage.ref(`firms/${firmId}/images/${fileName}`);
  return await fileRef.getDownloadURL();
}

export async function loadImageBuffer(
  firmId: string,
  fileName: string
): Promise<{
  buffer: ArrayBuffer;
  width: number;
  height: number;
  url: string;
  mimeType: string | undefined;
}> {
  const { url, mimeType } = await getImageInfo(firmId, fileName);

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = async () => {
      try {
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();

        resolve({
          buffer,
          width: img.width,
          height: img.height,
          url,
          mimeType,
        });
      } catch (error) {
        reject(error);
      }
    };
    img.onerror = (error) => reject(error);
    img.src = url;
  });
}

export async function loadImageDataUrl(
  firmId: string,
  fileName: string
): Promise<{
  dataUrl: string;
  width: number;
  height: number;
  url: string;
  mimeType: string | undefined;
}> {
  const { url, mimeType } = await getImageInfo(firmId, fileName);

  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = async () => {
      try {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const dataUrl = canvas.toDataURL('image/png');

        resolve({
          dataUrl,
          width: img.width,
          height: img.height,
          url,
          mimeType,
        });
      } catch (error) {
        reject(error);
      }
    };
    img.onerror = (error) => reject(error);
    img.src = url;
  });
}

export async function getImageInfo(
  firmId: string,
  fileName: string
): Promise<{ url: string; mimeType: string | undefined }> {
  const fileRef = storage.ref(`firms/${firmId}/images/${fileName}`);
  const [url, metadata] = await Promise.all([
    fileRef.getDownloadURL(),
    fileRef.getMetadata(),
  ]);

  return {
    url,
    mimeType: metadata.contentType ?? undefined,
  };
}
