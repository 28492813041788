import React, { useContext, useEffect } from 'react';
import './SidebarMenu.css';
import mainLogo from '../../assets/main-logo.png';
import hoverLogo from '../../assets/hover-logo.png';
import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import { signOut } from 'firebase/auth';
import { Badge } from '@mui/material';
import {
  FlywheelIcon,
  MattersIcon,
  TimeFeesIcon,
  ExpensesIcon,
  TasksIcon,
  InvoicesIcon,
  ResourcesIcon,
  WellnessIcon,
  OpsHrIcon,
  SettingsIcon,
  SignOutIcon,
} from './sidebarIcons';
import { AuthenticatedSessionContext } from '../../authenticatedSession/AuthenticatedSessionContext';
import * as Sentry from '@sentry/react';
import { useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface SidebarMenuItem {
  label: string;
  path: string;
  icon: React.ReactNode;
  badgeCount?: number;
  isHidden?: boolean;
}

export interface SidebarMenuProps {
  collapsed: boolean;
  // closeMobileMenu?: () => void; // Optional, for mobile menu close functionality
  // isMobileMenuOpen?: boolean;   // Optional, for mobile menu visibility state
}

const SidebarMenu = ({ collapsed }: { collapsed: boolean }) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const [logoSrc, setLogoSrc] = useState(mainLogo);
  const [flywheelMenuItem, setFlywheelMenuItem] = useState<SidebarMenuItem>({
    label: 'Matters',
    path: '/matters',
    icon: <MattersIcon />,
  });
  const [tasksMenuItem, setTasksMenuItem] = useState<SidebarMenuItem>({
    label: 'Tasks',
    path: '/tasks',
    icon: <TasksIcon />,
  });
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const openMobileMenu = () => setIsMobileMenuOpen(true);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  const menuItems: SidebarMenuItem[] = authSession
    ? [
        flywheelMenuItem,
        // {
        //   label: 'Matters',
        //   path: '/matters',
        //   icon: <MattersIcon />,
        // },
        {
          label: 'Time/Fees',
          path: '/fees',
          icon: <TimeFeesIcon />,
        },
        {
          label: 'Expenses',
          path: '/expenses',
          icon: <ExpensesIcon />,
        },
        tasksMenuItem,
        {
          label: 'Biz Dev',
          path: '/biz-dev',
          icon: <FlywheelIcon />,
          isHidden: true,
        },
        {
          label: 'Invoices',
          path: '/invoices',
          icon: <InvoicesIcon />,
        },
        {
          label: 'Resources',
          path: '/resources',
          icon: <ResourcesIcon />,
        },
        {
          label: 'Wellness',
          path: '/wellness',
          icon: <WellnessIcon />,
        },
        {
          label: 'Financials',
          path: '/ops-hr',
          icon: <OpsHrIcon />,
        },
        {
          label: 'Settings',
          path: '/settings',
          icon: <SettingsIcon />,
        },
      ]
    : [];

  useEffect(() => {
    if (!authSession) {
      return;
    }
    const cancelMatters = authSession.db.subscribeToMattersChanges(
      (snapshot) => {
        const unviewed = snapshot.docs.filter((doc) => {
          // Firestore doesn't support querying for undefined fields, so we need
          // to filter the results on `viewedByResponsibleAttorney` manually here.
          return (
            !doc.data().viewedByResponsibleAttorney ||
            doc.data().viewedByResponsibleAttorney !==
              doc.data().responsibleAttorney
          );
        });
        setFlywheelMenuItem((prev) => ({
          ...prev,
          badgeCount: unviewed.length,
        }));
      },
      [
        ['responsibleAttorney', '==', authSession.userId],
        ['status', '==', 'new'],
      ]
    );
    const cancelTasks = authSession.db.subscribeToTasksChanges(
      (snapshot) => {
        const unviewed = snapshot.docs.filter((doc) => {
          // Firestore doesn't support querying for undefined fields, so we need
          // to filter the results on `viewedByAssignedTo` manually here.
          return (
            !doc.data().viewedByAssignedTo ||
            doc.data().viewedByAssignedTo !== doc.data().viewedByAssignedTo
          );
        });
        setTasksMenuItem((prev) => ({
          ...prev,
          badgeCount: unviewed.length,
        }));
      },
      [
        ['assignedTo', '==', authSession.userId],
        ['isComplete', '!=', true],
      ]
    );
    return () => {
      cancelTasks();
      cancelMatters();
    };
  }, [authSession]);

  const handleLogoClick = () => {
    history.push('/dashboard'); // Navigate to the Dashboard view when the logo is clicked
  };

  const handleSignOut = async () => {
    if (!authSession) {
      history.push('/login');
      return;
    }

    Sentry.setUser(null);

    console.log('Will sign out of MSAL');
    const userAccount = authSession.msalApp.getAccountByUsername(
      authSession.userEmail
    );
    await authSession.msalApp.logoutRedirect({
      account: userAccount,
      onRedirectNavigate: () => {
        // Disable redirect and skip server sign-out
        // (see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#skipping-the-server-sign-out)
        return false;
      },
    });

    console.log('Will sign out of Firebase');
    await signOut(auth);

    console.log('Successfully signed out of Firebase and MSAL');
  };

  if (isMobile) return null; // Do not render the sidebar if on mobile view

  return (
    <div className={`sidebar-menu ${collapsed ? 'collapsed' : ''}`}>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
        <div className='logo-container'>
          <img
            src={logoSrc}
            alt='Logo'
            className='logo'
            onMouseEnter={() => setLogoSrc(hoverLogo)}
            onMouseLeave={() => setLogoSrc(mainLogo)}
            onClick={handleLogoClick}
          />
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}
        >
          {menuItems.map((item, index) => {
            return item.isHidden ? null : (
              <MenuLink
                key={index.toString()}
                innerKey={index.toString()}
                label={item.label}
                path={item.path}
                icon={item.icon}
                badgeCount={item.badgeCount}
                collapsed={collapsed}
              />
            );
          })}
        </div>
      </div>

      {authSession ? (
        <div className='sign-out-item' onClick={handleSignOut}>
          {collapsed && <SignOutIcon />}
          <p>Sign out</p>
        </div>
      ) : null}
      {isMobileMenuOpen && (
        <div className='mobile-menu'>
          <CloseIcon
            className='close-icon'
            onClick={closeMobileMenu}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              cursor: 'pointer',
            }}
          />
          <div className='mobile-menu-items'>
            {menuItems.map((item, index) => (
              <NavLink
                key={index.toString()}
                to={item.path}
                className='mobile-menu-item'
                onClick={closeMobileMenu}
              >
                {item.icon}
                <span>{item.label}</span>
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarMenu;

const MenuLink = ({
  innerKey,
  label,
  path,
  icon,
  badgeCount,
  collapsed,
}: {
  innerKey: string;
  label: string;
  path: string;
  icon: React.ReactNode;
  collapsed: boolean;
  badgeCount: number | undefined;
}) => {
  return (
    <NavLink
      key={innerKey}
      to={path}
      className={`sidebar-menu-item menu-item-${innerKey}`}
      activeClassName='sidebar-menu-item-active'
    >
      <Badge
        badgeContent={badgeCount}
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: '#5970f6',
            border: '1px solid white',
            color: '#fff',
            top: '2px',
            right: collapsed ? '0' : '-6px',
          },
        }}
      >
        <div
          className='sidebar-menu-item-content'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '0.3em',
          }}
        >
          {collapsed ? icon : null}
          <span className='sidebar-menu-item-text'>{label}</span>
        </div>
      </Badge>
    </NavLink>
  );
};
